import PopupContainerComponent from '@wix/thunderbolt-elements/src/components/PopupContainer/viewer/PopupContainer';
import PopupContainerController from '@wix/thunderbolt-elements/src/components/PopupContainer/viewer/PopupContainer.controller';


const PopupContainer = {
  component: PopupContainerComponent,
  controller: PopupContainerController
};


export const components = {
  ['PopupContainer']: PopupContainer
};

